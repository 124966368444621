import { Button, Flex } from '@applyboard/crystal-ui';
import { NavBarMenu } from './styles';
import { MenuOutlineIcon } from '@applyboard/ui-icons';

type AppbarLogoProps = {
  logo: string;
  title: string;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
};

export function AppBarLogo(props: AppbarLogoProps) {
  return (
    <Flex direction="row" align="center" gap={2}>
      <NavBarMenu>
        <Button
          aria-label="Main Menu"
          emphasis="transparent"
          leadIcon={MenuOutlineIcon}
          intent={props.isSidebarOpen ? 'primary' : 'secondary'}
          onClick={props.toggleSidebar}
        />
      </NavBarMenu>
      <img alt={props.title} src={props.logo} />
    </Flex>
  );
}
